import React from 'react';

import { Layout, SEO } from 'components';
import { CareersItem } from 'containers';

const OpenPosition = ({ location }) => {
  return (
    <Layout>
      <SEO title='Open Position' />
      {location && <CareersItem job={location} />}
    </Layout>
  );
};

export default OpenPosition;
